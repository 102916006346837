import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import styled from '@emotion/styled';
import Loupe from '../../static/icons/loupe-lightbox.svg';
import Close from '../../static/icons/close.svg';
import { Box } from '@3pc/layout-components-react';
import { devices } from '../styles/breakpoints';
import ResponsiveImage from '../base/ResponsiveImage';
import { colors } from '../styles/colors';
import { fallbackImage } from '../utils';

const PreviewButton = styled.button`
  display: block;
  width: 100%;
  height: auto;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  & > .gatsby-image-wrapper {
    min-width: 100%;
  }
`;

const LightboxIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  transition: all 200ms ease-in-out;

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-three']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-three']};
  }

  &:active {
    background: ${colors['blue-rgba-seven']};
  }

  &:focus {
    outline: 0;
  }
`;

const LightboxIcon = styled(Loupe)`
  margin-left: 3px;
  margin-top: 1px;
  pointer-events: none;
  transition: all 200ms ease-in-out;
`;

/* Warning! Styles of the lightbox are being handled in index.css */

const DialogContainer = styled(Dialog)`
  z-index: 10;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  margin-left: auto;
  margin-bottom: 10px;
  padding: 8px;
  max-width: 36px;
  max-height: 36px;

  @media ${devices.mobileMin} {
    padding: 10px;
    max-width: 44px;
    max-height: 44px;
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-three']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-three']};
  }

  &:active {
    background: ${colors['blue-rgba-seven']};
  }

  &:focus {
    outline: 0;
  }
`;

/* The value of padding-bottom is a result of the width/height of the image which was used for offline background image (3000/3526) */

const OfflineLightboxBackgroundImage = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  background-image: url(${fallbackImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const CopyrightsText = styled.small`
  font-style: italic;
  display: inline-block;
  margin-right: auto;
  margin-top: 10px;
`;

const Lightbox = ({
  defaultImage,
  mobileImage,
  lightboxImage,
  altAttribute,
  copyrightText,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const [isOnline, setIsOnline] = React.useState(true);

  const open = () => setIsActive(true);
  const close = () => setIsActive(false);

  React.useEffect(() => {
    const handleKeyEvent = (event) => {
      if (isActive && event.key === 'Escape') {
        close();
      }
    };

    window.addEventListener('keyup', handleKeyEvent);

    // clean up on UnMount
    return () => {
      window.removeEventListener('keyup', handleKeyEvent);
    };
  }, [isActive]);

  React.useEffect(() => {
    if (!window.navigator.onLine) {
      setIsOnline(false);
    }
  }, []);

  return (
    <Box>
      <PreviewButton type="button" onClick={open} className="lightbox-button">
        <ResponsiveImage
          defaultImage={defaultImage.childImageSharp.gatsbyImageData}
          mobileImage={mobileImage.childImageSharp.gatsbyImageData}
          altTag={altAttribute}
        />
        <span className="sr-only">Bild in einer Lightbox öffnen</span>
        <LightboxIconWrapper tabIndex="0">
          <LightboxIcon width="24" height="24" aria-hidden="true" />
        </LightboxIconWrapper>
      </PreviewButton>
      {isActive && (
        <DialogContainer aria-labelledby="lightbox-title">
          <CloseButton onClick={close}>
            <Close width="24" height="24" aria-hidden="true" />
            <span className="sr-only">Lightbox schließen</span>
          </CloseButton>
          {isOnline ? (
            <GatsbyImage
              image={lightboxImage.childImageSharp.gatsbyImageData}
              alt={altAttribute}
            />
          ) : (
            <OfflineLightboxBackgroundImage />
          )}
          <CopyrightsText id="lightbox-title">
            &copy; {copyrightText}
          </CopyrightsText>
        </DialogContainer>
      )}
    </Box>
  );
};

export default Lightbox;
