import React from 'react';
import Layout from '../components/Layout';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import PageHeader from '../components/PageHeader';
import DetailHeader from '../components/DetailHeader';
import Footer from '../components/Footer';
import Lightbox from '../components/Lightbox';
import { Box } from '@3pc/layout-components-react';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { footerHeight } from '../components/Footer';
import { maxWidthVals } from '../styles/container';

const ContentContainer = styled.div`
  background: ${colors.white};
  padding: 20px;
  margin: 0 auto 10px auto;
  width: calc(100% - 40px);
  max-width: ${maxWidthVals.uiMaxWidth};
  border-radius: 4px;
  position: relative;
  z-index: 0;
  margin-bottom: ${footerHeight.footerMobile};

  &::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: 100%;
    background: linear-gradient(180deg, ${colors.blue} 0%, ${colors.white} 85%);
    border-radius: 4px;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: 100%;
    background: ${colors.white};
    border-radius: 3px;
    z-index: -1;
  }

  p {
    font-size: 0.875em;
    line-height: 1.313em;
    margin-bottom: 20px;
  }
`;

export const query = graphql`
  query($slug: String!, $backgroundPageImage: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        slug
        title
        altTag
        copyright
      }
      body
    }
    image: file(relativePath: { eq: $backgroundPageImage }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    mobileImage: file(relativePath: { eq: $backgroundPageImage }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1.77778
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    lightboxImage: file(relativePath: { eq: $backgroundPageImage }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          transformOptions: { fit: CONTAIN }
        )
      }
    }
  }
`;

const BackgroundDetailPageTemplate = (data) => {
  const { image, mobileImage, lightboxImage } = data.data;
  const { altTag, copyright } = data.data.mdx.frontmatter;

  return (
    <>
      <PageHeader
        headerTitle={data.data.mdx.frontmatter.title}
        pageType={'detail-page'}
      />
      <Layout>
        <Box as="article" position="relative">
          <Lightbox
            defaultImage={image}
            mobileImage={mobileImage}
            lightboxImage={lightboxImage}
            altAttribute={altTag}
            copyrightText={copyright}
          />

          <DetailHeader
            pageType="detail-page"
            headerTitle={data.data.mdx.frontmatter.title}
            slug={data.data.mdx.frontmatter.slug}
          />

          <ContentContainer>
            <MDXRenderer>{data.data.mdx.body}</MDXRenderer>
          </ContentContainer>
        </Box>
      </Layout>
      <Footer />
    </>
  );
};

export default BackgroundDetailPageTemplate;
